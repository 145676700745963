import PropType from "prop-types";

const SortBy = ({onSortHandler}) => {
    return (
        <select defaultValue="default" onChange={(event => onSortHandler(event.target.value))}>
            <option value="default">Default</option>
            <option value="nameA2Z">Nombre (A-Z)</option>
            <option value="nameZ2A">Nombre (Z-A)</option>
            <option value="priceLow2High">Precio (Menor a Mayor)</option>
            <option value="priceHigh2Low">Precio (Mayor a Menor)</option>
        </select>
    );
};

SortBy.propTypes = {
    onSortHandler: PropType.func.isRequired
}

export default SortBy;