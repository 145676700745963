const LoadMore = ({className, onLoadMorHandler, productLength, productPerPage}) => {

    return (
        productLength >= productPerPage && (
            productLength !== productPerPage ? (
                <div className={`text-center tt_product_showmore ${className}`}>
                    <button className="btn" style={{marginBottom: 44}} onClick={onLoadMorHandler}>CARGAR MAS</button>
                </div>
            ) : (
                <div className={`text-center tt_product_showmore ${className}`}>
                    <div style={{marginBottom: 44, color: '#bbb'}} >VUELVE MAS TARDE POR MAS PRODUCTOS</div>
                </div>
            )
        )
    );
};

export default LoadMore;